@import "./scss/global/shared.scss";
/* HOMEPAGE TOP
-------------------------------------------------------------- */
.homepage-top {
    margin-bottom: 60px;
    width: 100%;
    padding-bottom: 0;

    &__slider {
        position: relative;
        height: 100%;
        width: 100vw;
        margin-left: calc((-100vw + 100%) / 2);
        margin-right: calc((-100vw + 100%) / 2);
        padding: 20px 0;
        background-image: linear-gradient(transparent, $body-bg),
            $background-pattern;
        background-image: linear-gradient(transparent, $body-bg),
            image-set(
                $background-pattern-avif type('image/avif'),
                $background-pattern type('image/jpeg')
            );

        @media #{$media-sm} {
            margin-bottom: 30px;
            padding-bottom: 0;
        }

        //@media #{$media-xs} {
        @media (max-width: 1024px) {
            margin-bottom: 30px;
        }

        .slider-main {
            height: 100%;
        }
    }

    &__chat {
        width: 100vw;
        margin-left: calc((-100vw + 100%) / 2);
        margin-right: calc((-100vw + 100%) / 2);
        padding: 20px 0;
        background-image: $background-pattern;
        background-image: image-set(
                $background-pattern-avif type('image/avif'),
                $background-pattern type('image/jpeg')
        );

        @media #{$media-xs} {
            padding-bottom: 0;
            width: 90vw;
            margin-left: calc((-90vw + 100%) / 2);
            margin-right: calc((-90vw + 100%) / 2);
        }

        .container {
            padding: 0;
            width: 100%;
        }
    }

    .product-shop__wishlist {
        display: none !important;
    }
    .add-to-cart-button-wrapper {
        display: inline-block;
        width: 10% !important;
    }

    // .chat-box {
    //     height: 100%;

    //     &__title {
    //         &::selection,
    //         img::selection,
    //         .title::selection {
    //             background: transparent;
    //         }
    //     }
    // }
}

/* HOMEPAGE PRODUCT LISTS
-------------------------------------------------------------- */
.homepage-products {
    margin: 60px 0;
    margin-top: 60px !important;

    @media #{$media-xs} {
        margin-top: 10px !important;
    }

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;

        @media #{$media-xs} {
            margin-top: 60px !important;
        }
    }

    .page-subtitle {
        position: relative;

        @media #{$media-xs} {
            text-align: center;
        }
    }

    > .row {
        @media #{$media-sm} {
            max-width: 626px;
            margin-left: auto;
            margin-right: auto;
        }

        .product-list__item {
            @media #{$media-sm} {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
    .product-shop__wishlist {
        display: none !important;
    }
}

/* CONTENT LINKS
-------------------------------------------------------------- */
.homepage-content {
    margin-bottom: 15px;

    .article-list {
        margin-bottom: 40px;

        &:not(.article-list--img) {
            @media #{$media-md-up} {
                &:nth-of-type(2n + 1) {
                    clear: both;
                }
            }
        }
    }

    .article-list--img {
        @media #{$media-lg} {
            &:nth-of-type(4n + 1) {
                clear: both;
            }
        }

        @media #{$media-md} {
            &:nth-of-type(3n + 1) {
                clear: both;
            }
        }

        @media #{$media-sm} {
            &:nth-of-type(2n + 1) {
                clear: both;
            }
        }
    }
}

.cms-index-index {
    .content-main {
        padding-bottom: 20px;

        @media #{$media-xs} {
            padding-bottom: 10px;
        }
    }

    .footer {
        padding-top: 0;

        &__mega-links-wrapper {
            background: #f5f5f5;
            padding-top: 30px;

            @media #{$media-xs} {
                padding-top: 0;
            }
        }
    }
}
